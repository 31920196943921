import BasicService from "./BasicService";

class ExchangeService extends BasicService {
  constructor() {
    super();
  }

  getExchange() {
    let url = `/exchange`;
    return this.axios.get(url).then(response => response.data);
  }
  addExchange(exchange) {
    let url = `/exchange`;
    return this.axios.post(url,exchange).then(response => response.data);
  }
  updateExchange(exchange) {
    let url = `/exchange/${exchange.exchangeId}`;
    return this.axios.put(url,exchange).then(response => response.data);
  }
  deleteExchange(exchange) {
    let url = `exchange/${exchange.exchangeId}`;
    return this.axios.delete(url).then(response => response.data);
  }
}
const service = new ExchangeService();
export default service;
