import BasicService from "./BasicService";

class NoteService extends BasicService {
  constructor() {
    super();
  }

  getNoteList() {
    let url = `/note`;
    return this.axios.get(url).then(response => response.data);
  }
  addNote(note) {
    let url = `/note`;
    return this.axios.post(url,note).then(response => response.data);
  }
  updateNote(note) {
    let url = `/note/${note.noteId}`;
    return this.axios.put(url,note).then(response => response.data);
  }
  deleteNote(note) {
    let url = `note/${note.noteId}`;
    return this.axios.delete(url).then(response => response.data);
  }
}
const service = new NoteService();
export default service;
